import { BtnAtrasCalculadoras } from "../navegacion/btn_atras_calculadoras";
import {
  PlusOutlined,
  RightOutlined,
  LeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { TablaCompleta } from "../tablas/tabla_simple/tabla_simple";
// import { ChartEmisionesMulti } from "../charts/ChartEmisionesMulti";
import { InputPrecio } from "../componentes/inputComponente";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import { FaQuestionCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import {
  traerDatos,
  HeadersAuth,
  CheckExpiracion,
  traerDatosObjeto,
  fNum,
  cambioValor,
  sacaComas,
} from "../funciones_utiles";
import {
  validarNumericoDecimal,
  validarFechaPeriodo,
  validarSelector,
} from "../validaciones";
import PresetResultado from "./presetResultado";
import { BtnPrincipal } from "../navegacion/BtnPrincipal";
import { Tooltip, Tour } from "antd";

const MySwal = withReactContent(Swal);

// const MemoizedChartEmisionesMulti = React.memo(ChartEmisionesMulti);
//DESCARGAR MOMENT JS
export const CompCalculadoraEnergiaImportada = ({ sesion, titulo }) => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;

  const URI_TIPOS_ENERGIA =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/tipos_energia_importada";
  // console.log(empresasUsuario)
  // console.log(empresasUsuario.some(checkEmpresa))

  const refTipoEnergia = useRef(null);
  const refCantidad = useRef(null);
  const refMes = useRef(null);
  const refBtnGuardar = useRef(null);
  // const refGrafico = useRef(null);
  const refTabla = useRef(null);

  const [abrirAyuda, setAbrirAyuda] = useState(false);

  const propsNext = {
    children: (
      <RightOutlined style={{ color: "white", marginBottom: "20px" }} />
    ),
    style: {
      height: 31,
    },
  };
  const propsFin = {
    children: (
      <CloseOutlined style={{ color: "white", marginBottom: "20px" }} />
    ),
    style: {
      height: 31,
    },
  };
  const propsPrev = {
    children: <LeftOutlined style={{ color: "white", marginBottom: "20px" }} />,
    style: {
      height: 31,
    },
  };
  const pasos = [
    {
      title: t("calculadoras.pasos.titulo3"),
      description: t("calculadoras.pasos.subtitulo34"),
      target: () => refMes.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo19"),
      description: t("calculadoras.pasos.subtitulo35"),
      target: () => refTipoEnergia.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo2"),
      description: t("calculadoras.pasos.subtitulo20"),
      target: () => refCantidad.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },

    {
      title: t("calculadoras.pasos.titulo4"),
      description: t("calculadoras.pasos.subtitulo4"),
      target: () => refBtnGuardar.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo6"),
      description: t("calculadoras.pasos.subtitulo6"),
      target: () => refTabla.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsFin,
    },
  ];

  // const anioActual = new Date().getFullYear();
  // const [yearRange, setYearRange] = useState(anioActual);
  // const [sufijo, setSufijo] = useState("S/N");

  const [datosTable, setDatosTable] = useState([]);

  const [opcionesTiposEnergia, setOpcionesTiposEnergia] = useState([]);

  const [idUnico, setIdUnico] = useState("");
  const [tempDatosCalculo, setTempDatosCalculo] = useState({});
  const [idEmpresa, setIdEmpresa] = useState(sesion.empresaActiva);
  const [idSede, setIdSede] = useState(sesion.sedeActiva);
  const [fuenteEmision, setFuenteEmision] = useState("");

  const handleQuestionIconClick = () => {
    Swal.fire({
      imageUrl: "img/imagen1.png",
      imageHeight: 65,
      text: "UK Government GHG Conversion Factors for Company Reporting",
    });
  };

  useEffect(() => {
    setIdEmpresa(sesion.empresaActiva);
    setIdSede(sesion.sedeActiva);
    if (idUnico) {
      (async () => {
        const URI_CONSUMOS_COMBUSTION_UNICOS =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/datos_consumo_unico?type=imported_energy&unique_id=" +
          idUnico +
          "&ids=" +
          idSede +
          "&id=" +
          idEmpresa;
        const datosConsumo = await traerDatosObjeto(
          URI_CONSUMOS_COMBUSTION_UNICOS
        );
        // Aplicar formato a los Kwhs consumidos
        datosConsumo.fuel_quantity = cambioValor(
          parseFloat(datosConsumo.fuel_quantity).toFixed(2)
        );

        setTempDatosCalculo(datosConsumo);
      })();
    } else {
      setTempDatosCalculo({
        energy_type: "",
        fuel_quantity: "",
        month_year: "",
      });
    }
  }, [idUnico, idSede, idEmpresa, sesion.empresaActiva, sesion.sedeActiva]);

  const [show, setShow] = useState("");
  const [fetchDatos, setFetchDatos] = useState([]);

  var [tooltipVisible, setTooltipVisible] = useState({
    energy_type: false,
    fuel_quantity: false,
    month_year: false,
  });
  const [errores, setErrores] = useState({
    energy_type: "",
    fuel_quantity: "",
    month_year: "",
  });
  const campos = [
    { name: "month_year", validator: validarFechaPeriodo },
    { name: "energy_type", validator: validarSelector },
    { name: "fuel_quantity", validator: validarNumericoDecimal },
  ];
  useEffect(() => {
    const actualizarChart = async () => {
      setDatosTable(fetchDatos);
    };
    actualizarChart();
  }, [fetchDatos, show]);

  useEffect(() => {
    (async () => {
      setOpcionesTiposEnergia(await traerDatos(URI_TIPOS_ENERGIA));
      setFetchDatos(await traerDatos(URI_CONSUMOS_ENERGIA));
    })();
  }, [show, URI_TIPOS_ENERGIA]);

  let URI_NUEVO_CALCULO;
  const URI_CONSUMOS_ENERGIA =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_consumo?type=energia_importada&id=" +
    idEmpresa +
    "&ids=" +
    idSede;
  const elScroll = { x: 0 };
  const Columns = [
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.periodo")}</span>
        </div>
      ),
      key: "fecha",
      align: "center",
      sorter: (a, b) => a.fecha.localeCompare(b.fecha),
      render: (a) => (
        <div style={{ fontSize: "12px", padding: "2px 4px" }}>
          {moment(`${a.fecha}`, "YYYY-MM").format("MM/YYYY")}
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}> {t("tablas.cantidad")}</span>
        </div>
      ),
      key:"cantidad",
      align: "right",
      sorter: (a, b) => a.kwhs_consumidos - b.kwhs_consumidos,
      render: (a) => (
        <div style={{ fontSize: "12px", padding: "2px 4px" }}>
          {fNum(a.kwhs_consumidos)}
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}> {t("tablas.tipoEnergia")}</span>
        </div>
      ),
      key:"tipo",
      align: "left",
      sorter: (a, b) => -a.tipo.localeCompare(b.tipo),
      render: (a) => (
        <div style={{ fontSize: "12px", padding: "2px 4px" }}>
          {currentLanguage === "es" ? a.tipo : a.tipo_en}
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span
            style={{ fontSize: "0.8em" }}
            dangerouslySetInnerHTML={{ __html: t("tablas.toneladas") }}
          />
        </div>
      ),
      key: "kgcodose_emitidog",
      align: "right",
      sorter: (a, b) => a.kgcodose_emitido - b.kgcodose_emitido,
      render: (a) => (
        <div style={{ fontSize: "12px", padding: "2px 4px" }}>
          {fNum(a.kgcodose_emitido)}
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>+ Info</span>
        </div>
      ),
      key: "id",
      align: "center",
      render: (dato) => (
        <div className="row">
          <div className="col align-items-center">
            {dato.compensado === 1 && (
              <>
                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => borrar(e, dato.id)}
                >
                  <DeleteOutlined />
                </button>

                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => traerCalculo(e, dato.id)}
                >
                  <EditOutlined />
                </button>
              </>
            )}
            {dato.compensado === 2 && (
              <span>
                <b> {t("compensacion.estados.pendiente")}</b>
              </span>
            )}
            {dato.compensado === 3 && (
              <span>
                <b>{t("compensacion.estados.compensado")}</b>
              </span>
            )}
          </div>
        </div>
      ),
    },
  ];

  const handleChange = async (e) => {
    const { id, value } = e.target;
    // console.log("ES : " + value);

    setTempDatosCalculo({
      ...tempDatosCalculo,
      [id]: value,
    });

    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor, t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };
    const campoActual = campos.find((campo) => campo.name === id);

    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };

  const guardar = async (e) => {
    e.preventDefault();
    let hayErrores = false;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosCalculo[campo.name]);
      const resultadoValidacion = await campo.validator(
        valorCampo,
        tooltipVisible
      );

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }
    if (hayErrores) {
      setErrores(erroresTemp);
      return;
    } else {
      tempDatosCalculo.fuel_quantity = await sacaComas(
        tempDatosCalculo.fuel_quantity
      );

      if (idUnico) {
        URI_NUEVO_CALCULO =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/calculo_importados_kwhs?id=" +
          idEmpresa +
          "&ids=" +
          idSede +
          "&unique_id=" +
          idUnico;
      } else {
        URI_NUEVO_CALCULO =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/calculo_importados_kwhs?id=" +
          idEmpresa +
          "&ids=" +
          idSede +
          "&unique_id=none";
      }

      const registro = await axios.post(
        URI_NUEVO_CALCULO,
        tempDatosCalculo,
        HeadersAuth
      );

      if (registro.data.errors) {
        const errores = registro.data.errors;
        // console.log(errores)
        const msgError = errores.map((error) => {
          return (
            "<pre>" +
            (currentLanguage === "es" ? error.msg.ES : error.msg.EN) +
            "</pre>"
          );
        });
        MySwal.fire({
          html: `${msgError}`,
          imageUrl: "img/lechuza_triste.png",
          imageHeight: 65,
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              html: (
                <PresetResultado resultado={fNum(registro.data.result)} t={t} />
              ),
              imageUrl: "img/mensajes/resultado.svg",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });
            setTempDatosCalculo({
              energy_type: "",
              fuel_quantity: "",
              month_year: "",
            });
            if (show === "") {
              setShow("guardado");
            } else {
              setShow("");
            }
            setIdUnico("");
          }
        }
      }
    }
  };
  const traerCalculo = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setIdUnico(value);
  };
  const borrar = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    //const { value } = e.target;
    // console.log(value)
    const URI_BORRAR_CONSUMO =
      process.env.REACT_APP_DOMINIO +
      process.env.REACT_APP_PUERTO +
      "/borrar_consumo?type=imported_energy&id=" +
      idEmpresa +
      "&ids=" +
      idSede +
      "&unique_id=" +
      value;
    const borrar = await axios.delete(URI_BORRAR_CONSUMO, HeadersAuth);
    if (borrar.data.code === 401 || borrar.data.code === 402) {
      MySwal.fire({
        text: t("mensajes.mensaje12"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
    } else {
      if (borrar.data.code === 409) {
        CheckExpiracion();
      } else {
        if (borrar.data.code === 200) {
          MySwal.fire({
            text: t("mensajes.mensaje10"),
            imageUrl: "img/borrar.png",
            imageHeight: 50,
            confirmButtonText: "Ok",
          });
          if (show === "") {
            setShow("borrado");
          } else {
            setShow("");
          }
          setIdUnico("");
        }
      }
    }
  };

  return (
    <>
      <br></br>
      <div className="perfil row g-3 justify-content-center ms-4 me-4">
        <div className="row row-form px-2">
          <div className="col-12 col-md-4">
            <div className="contenedor-form-gases">
              <div className="contenedor-titulo w-100">
                <h2 className="titulo-calculadoras">
                  {titulo}
                  <Tooltip title={t("tooltip.ayuda")} placement="top">
                    <FaQuestionCircle
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => setAbrirAyuda(true)}
                    />
                  </Tooltip>
                  {idUnico ? (
                    <>
                      <Tooltip title={t("tooltip.nuevoCalculo")}>
                        <PlusOutlined
                          className="btnnuevocalculo p-1 mb-1"
                          onClick={(e) => setIdUnico("")}
                        />
                      </Tooltip>{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </h2>
              </div>
              <form onSubmit={guardar}>
                <div className="row pb-2 mb-3 justify-content-center custom-select-row">
                  <div
                    ref={refMes}
                    className="col-md-6 col-12 d-flex flex-column align-items-center"
                  >
                    <div className="d-flex align-items-center w-100 justify-content-start">
                      <label className="select_lista text-left">
                        {t("tablas.mes")}
                      </label>{" "}
                    </div>
                    <Tooltip
                      title={
                        !!errores.month_year && (
                          <span>{errores.month_year}</span>
                        )
                      }
                      open={tooltipVisible.month_year}
                      color="#1CA6AF"
                    >
                      <div className="w-100">
                        <input
                          className="form-control w-100"
                          type="month"
                          min="2010-01"
                          max={`${new Date().getFullYear()}-${(
                            "0" +
                            (new Date().getMonth() + 1)
                          ).slice(-2)}`}
                          id="month_year"
                          value={tempDatosCalculo.month_year || ""}
                          onChange={handleChange}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    ref={refTipoEnergia}
                    className="col-md-6 col-12 d-flex flex-column align-items-center"
                  >
                    <div className="d-flex align-items-center w-100 justify-content-start">
                      <label
                        className="select_lista text-left"
                        style={{ flex: "1 0 auto" }}
                      >
                        {" "}
                        {t("tablas.tipoEnergia")}
                      </label>{" "}
                    </div>
                    <Tooltip
                      title={
                        !!errores.energy_type && (
                          <span>{errores.energy_type}</span>
                        )
                      }
                      open={tooltipVisible.energy_type}
                      color="#1CA6AF"
                    >
                      <select
                        className="form-select form-select-sm w-100"
                        id="energy_type"
                        onChange={handleChange}
                        value={tempDatosCalculo.energy_type || ""}
                      >
                        <option key="" value="">
                          {t("empresa.datosSede.elejirOpcion")}
                        </option>
                        {opcionesTiposEnergia.map((option) => (
                          <option key={option.id} value={option.id}>
                            {currentLanguage === "es"
                              ? option.tipo
                              : option.tipo_en}
                          </option>
                        ))}
                      </select>
                    </Tooltip>
                  </div>
                  <div
                    ref={refCantidad}
                    className="col-md-6 col-12 d-flex flex-column align-items-center"
                  >
                    <div className="d-flex align-items-center w-100 justify-content-start">
                      <label
                        className="select_lista text-left"
                        style={{ flex: "1 0 auto" }}
                      >
                        {t("tablas.cantidad")}
                      </label>
                    </div>
                    <Tooltip
                      title={
                        !!errores.fuel_quantity && (
                          <span>{errores.fuel_quantity}</span>
                        )
                      }
                      open={tooltipVisible.fuel_quantity}
                      color="#1CA6AF"
                    >
                      <div className="w-100">
                        <InputPrecio
                          className="form-control"
                          idCampo={"fuel_quantity"}
                          elValue={tempDatosCalculo.fuel_quantity || ""}
                          elPlaceholder={"Kwhs"}
                          elSufijo={"Kwhs"}
                          onChange={handleChange}
                        />
                      </div>
                    </Tooltip>
                  </div>

                  <div
                    ref={refBtnGuardar}
                    className="col-md-6 col-12 d-flex flex-column align-items-center gap-1"
                  >
                    <div className="d-flex align-items-center w-100 justify-content-end mt-4">
                      <BtnPrincipal name={t("botones.calcular")} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-12 col-md-8">
            <div className="contenedor-tabla-gases">
              <h2 className="texto-explicacion"> {t("tablas.info4")}</h2>
              <div ref={refTabla}>
                <TablaCompleta
                  fetchDatos={datosTable}
                  Columns={Columns}
                  elScroll={elScroll}
                  sizeVariant="small"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tour
        open={abrirAyuda}
        onClose={() => setAbrirAyuda(false)}
        steps={pasos}
      />
    </>
  );
};
